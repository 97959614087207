import Card from 'components/card'
import TooltipHorizon from 'components/tooltip'
import { cn } from 'utils/cn'

/**
 * Calculates the number of days between a given date and the current date.
 *
 * @param {string} givenDateStr - The date to calculate the days from.
 * @return {string} The calculated number of days ago.
 */
function daysAgo(givenDateStr) {
  const givenDate = new Date(givenDateStr)
  const currentDate = new Date()
  const timeDifference = currentDate - givenDate
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  return `${daysDifference} days ago`
}

const ServiceUptimeStatusCard = ({ uptimeData }) => {
  return (
    <Card
      extra={
        'pl-6 py-3 !mt-0 h-full w-full h-full w-full overflow-y-hidden overflow-x-scroll 2xl:overflow-x-hidden'
      }
    >
      <div className="my-4 font-bold text-[#2B3674] dark:text-white">
        {uptimeData.projectName}
      </div>
      {uptimeData.data.map((item, index) => (
        <div className="flex h-16 items-center gap-3" key={index}>
          <div
            className={cn(
              'h-fit min-w-14 whitespace-nowrap rounded-[7px] px-2 text-center text-sm font-medium',
              {
                'bg-[#C9FBD580] text-horizonGreen-500 dark:bg-[#C9FBD50d]':
                  item.uptimePercentage >= 80,
                'bg-horizonPurple-100/50 text-blueSecondary':
                  item.uptimePercentage >= 60 && item.uptimePercentage < 80,
                'bg-[#FFF6DA]/100 text-horizonOrange-500 dark:bg-[#FFF6DA]/20':
                  item.uptimePercentage >= 30 && item.uptimePercentage < 60,
                'bg-[#EE5D501A] text-[#EE5D50]':
                  item.uptimePercentage >= 0 && item.uptimePercentage < 30,
              }
            )}
          >
            {item.uptimePercentage + ' %'}
          </div>
          <div className="min-w-72 grow whitespace-nowrap font-bold text-[#2B3674] dark:text-white">
            {item.serviceName}
          </div>

          <div className="flex flex-col pr-6">
            <div className="flex justify-center gap-0.5">
              {item.data.map((tick, index) => (
                <TooltipHorizon
                  key={index}
                  content={new Date(tick.date).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}
                  placement="top"
                  extra={`border ${
                    tick.status === 'up'
                      ? 'border-[#56DC88]'
                      : 'border-[#EE5D50]'
                  }`}
                  trigger=<div
                    className={cn('h-6 w-1 rounded-sm', {
                      'bg-[#56DC88] hover:bg-[#56DC88]/60':
                        tick.status === 'up',
                      'bg-[#EE5D50] hover:bg-[#EE5D50]/60':
                        tick.status === 'down',
                    })}
                  ></div>
                ></TooltipHorizon>
              ))}
            </div>
            <div className="flex justify-between">
              <div className="text-xs font-medium text-[#A3AED0]">
                {daysAgo(item.fromDate)}
              </div>
              <div className="text-xs font-medium text-[#A3AED0]">Today</div>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}

export default ServiceUptimeStatusCard
