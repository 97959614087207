import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import CryptoJS from 'crypto-js'
const apiService = ApiCaller()
/**
 * Get all application data from the API.
 *
 * @return {Promise} The response from the API call.
 */

/**
 * Retrieves all application data from the API.
 *
 * @param {number} page - The page number of the data to retrieve.
 * @param {string} [search=''] - An optional search query to filter the data.
 * @return {Promise} A promise that resolves to the response from the API call.
 */
const getAllApplicationData = async (projectId, page, search = '') => {
  try {
    let apiUrl = apiConfig.GET_ALL_APPLICATION + `/${projectId}/${page}`
    if (search) {
      apiUrl = apiUrl + `/${search}`
    }
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}
/**
 * Retrieves all projects data based on page and optional search term.
 *
 * @param {number} page - The page number to retrieve projects data from.
 * @param {string} [search=''] - The optional search term to filter projects.
 * @return {Promise} The response containing the projects data.
 */
const getAllProjectsData = async (page, search = '') => {
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  try {
    let apiUrl = apiConfig.GET_ALL_PROJECTS + `/${organizationData?.id}/${page}`
    if (search) {
      apiUrl = apiUrl + `/${search}`
    }
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}
/**
 * Decrypts an encrypted topic ID using the AES algorithm.
 *
 * @param {string} encryptedTopicId - The encrypted topic ID.
 * @return {object|null} The decrypted topic ID as a JSON object, or null if decryption fails.
 */
const decryptFunction = (encryptedTopicId) => {
  try {
    let bytes = CryptoJS.enc.Base64.parse(encryptedTopicId).toString(
      CryptoJS.enc.Utf8
    )
    let decryptedTopicId = CryptoJS.AES.decrypt(bytes, 'secret').toString(
      CryptoJS.enc.Utf8
    )
    return JSON.parse(decryptedTopicId)
  } catch (error) {
    console.error('Error decrypting topic ID:', error)
    return null
  }
}

/**
 * Retrieves user information by making an asynchronous API call.
 *
 * @return {Promise} The response containing the user information.
 */
const getUserInformations = async () => {
  try {
    const apiUrl = apiConfig.GET_USER_INFO
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Stores the user information in the local storage.
 *
 * @return {Promise<void>} A promise that resolves when the user information is successfully stored.
 */
const storeUserInfo = async () => {
  try {
    const response = await getUserInformations()
    if (response.status === 200) {
      localStorage.setItem('userData', JSON.stringify(response?.data?.data))
    }
  } catch (error) {
    console.log('getUserInfo Error', error)
  }
}

/**
 * Retrieves artifact details by making an asynchronous API call.
 *
 * @return {Promise} The response containing the artifact details.
 */
const getArtifactDetails = async () => {
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  try {
    const apiUrl = apiConfig.GET_ARTIFACT_DATA.replace(
      ':organization_id',
      organizationData?.id
    )
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Encrypts the given word using AES algorithm with an optional key.
 *
 * @param {string} word - The word to be encrypted.
 * @param {string} [key='secret'] - The key used for encryption.
 * @return {string} The encrypted data in Base64 format.
 */
const encryptId = (word, key = 'secret') => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
  return encData
}

/**
 * Converts an array of objects into an array of options objects.
 *
 * @param {Array<Object>} arrayOfObjects - The array of objects to be converted.
 * @return {Array<Object>} The array of options objects.
 */
const convertToOptions = (arrayOfObjects) => {
  const options = arrayOfObjects.map((obj) => ({
    value: obj.full_name,
    label: obj.full_name,
  }))

  return options
}

/**
 * Retrieves organization details by making an asynchronous API call.
 *
 * @return {Promise} The response containing the organization details.
 */
const getOrganization = async () => {
  try {
    const apiUrl = apiConfig.GET_ORGANIZATION_DETAILS
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Sets the repository name in the local storage by converting the provided repository data to a JSON string
 * and removing any double quotes.
 *
 * @param {Object} repoData - The repository data to be stored.
 * @return {void} This function does not return anything.
 */
const setRepoName = (repoData) => {
  localStorage.setItem(
    'repoFullName',
    JSON.stringify(repoData).replace(/"/g, '')
  )
}

/**
 * Retrieves the repository name from local storage.
 *
 * @return {string} The repository name retrieved from local storage.
 */
const getRepoName = () => {
  return localStorage.getItem('repoFullName')
}

/**
 * Handles a billing API call by sending a POST request to the SET_ALL_BILLING endpoint with the domain name
 * obtained from the current window location. If the response code is 200, the data is returned. Otherwise, an
 * error message is logged. If an error occurs during the API call, it is also logged.
 *
 * @return {Promise<Object>|undefined} The data from the response if the response code is 200, otherwise undefined.
 */
const handleBillingApiCall = async () => {
  try {
    const payload = { domainName: window.location.hostname }
    const apiUrl = apiConfig.SET_ALL_BILLING
    const response = await apiService.apiCall('post', apiUrl, payload)
    if (response?.data?.code === 200) {
      return response?.data?.data
    } else {
      console.error('Invalid response from server')
    }
  } catch (error) {
    // Handle error
    console.error('Error during API call:', error)
  }
}

const getOneBillingData = async () => {
  const domainName = window.location.hostname
  const apiUrl = apiConfig.GET_ALL_BILLING.replace(':domain', domainName)
  try {
    const response = await apiService.apiCall('get', apiUrl)
    if (response?.data?.code === 200 || response?.data?.code === 204) {
      return response?.data?.data
    } else {
      console.error('Error response from API:', response)
    }
  } catch (error) {
    console.error('Error fetching config table data:', error)
  }
}

/**
 * Handles the logout process by making an API call to log out the user.
 *
 * @return {void} This function does not return anything.
 */
const handleLogout = async () => {
  try {
    const apiUrl = apiConfig.LOGOUT_USER
    const payload = {}
    const response = await apiService.apiCall('post', apiUrl, payload)
    if (response.status === 200) {
      localStorage.clear()
      return
    }
  } catch (error) {
    console.error('Error during API call:', error)
  }
}

/**
 * Determines if the service is the only service based on the input service type.
 *
 * @param {string} service - The type of service to check.
 * @return {Object} Object indicating if the service is the only service with a boolean value.
 */
const isOnlyService = (service) => {
  switch (service) {
    case 'webApp':
    case 'frontend':
    case 'cron':
      return { isNotService: true }
    case 'postgresql':
    case 'redis':
      return { isNotService: false }
    default:
  }
}

/**
 * Converts a string to kebab case.
 *
 * @param {string} str - The string to convert.
 * @return {string} The converted string in kebab case.
 */
const toKebabCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Handle camelCase
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/_/g, '-') // Replace underscores with -
    .toLowerCase() // Convert to lower case
}

/**
 * Retrieves information for a single application by its ID.
 *
 * This function constructs an API URL by replacing the `:applicationId` placeholder in the
 * `GET_APPLICATION_INFO` endpoint with the provided `applicationId`. It then makes a GET request
 * to this URL using the `apiService` and returns the result.
 *
 * @param {string} applicationId - The ID of the application to retrieve information for.
 * @return {Promise<Object>} A promise that resolves to the response from the API call containing the application's information.
 */
const getSingleApplicationInfo = (applicationId) => {
  const apiUrl = apiConfig.GET_APPLICATION_INFO.replace(
    ':applicationId',
    applicationId
  )
  return apiService.apiCall('get', apiUrl)
}

const checkDomainName = (payload) => {
  const apiUrl = apiConfig.CHECK_DOMAINNAME
  return apiService.apiCall('post', apiUrl, payload)
}

export {
  getAllApplicationData,
  decryptFunction,
  encryptId,
  getUserInformations,
  getArtifactDetails,
  convertToOptions,
  getAllProjectsData,
  getOrganization,
  storeUserInfo,
  setRepoName,
  getRepoName,
  isOnlyService,
  toKebabCase,
  handleBillingApiCall,
  handleLogout,
  getOneBillingData,
  getSingleApplicationInfo,
  checkDomainName,
}
