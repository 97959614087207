import React from 'react'
import { HiPlus } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

/**
 * Renders the Get Started button component.
 *
 * @param {string} buttonText - The text displayed on the button.
 * @param {boolean} isCreateService - Indicates if the service is being created.
 * @param {string} projectId - The ID of the project.
 */
function GetStartedButton({ buttonText, isCreateService = false, projectId }) {
  let navigate = useNavigate()
  return (
    <>
      <div>
        <button
          onClick={() => {
            navigate(
              projectId
                ? `/application/${projectId}${
                    isCreateService ? `?createService=1` : ''
                  }`
                : `/projects`
            )
          }}
          className="flex items-center rounded-[20px] bg-brand-500 px-5 py-2 text-sm font-medium text-white transition duration-200 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 sm:px-3 sm:text-xs md:text-base lg:px-5 lg:text-base"
        >
          {buttonText}{' '}
          <span className="ml-2 text-xl">
            <HiPlus />
          </span>
        </button>
      </div>
    </>
  )
}

export default GetStartedButton
