import React from 'react'
import ReactApexChart from 'react-apexcharts'

class LineChart extends React.Component {
  /**
   * Constructor for initializing the LineChart component with chart data and options.
   *
   * @param {object} props - The properties passed to the component.
   */
  constructor(props) {
    super(props)
    const chartData = props?.chartData?.length
      ? props.chartData.map((item) => {
          const date = new Date(item.x)
          return {
            x:
              date instanceof Date && !isNaN(date)
                ? date.getTime()
                : new Date().getTime(),
            y: item.y,
          }
        })
      : [{ x: new Date().getTime(), y: 0 }] // Default value to prevent errors

    this.state = {
      chartData: [
        {
          name: 'Throughput',
          data: chartData,
        },
      ],
      chartOptions: props?.chartOptions || {},
    }
  }

  /**
   * Renders a ReactApexChart component with the specified options and data.
   *
   * @return {JSX.Element} The ReactApexChart component.
   */
  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="line"
        width="100%"
        height="100%"
      />
    )
  }
}

export default LineChart
