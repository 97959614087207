import TooltipHorizon from 'components/tooltip'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

/**
 * Renders a metrics card component with a label and a value.
 *
 * @param {Object} props - An object containing the label and value properties.
 * @param {string} props.label - The label to be displayed in the tooltip.
 * @param {number} props.value - The value to be displayed in the circular progress bar.
 * @return {JSX.Element} A metrics card component.
 */
const MetricsCard = ({ label, value }) => {
  return (
    <div className="flex flex-col rounded-2xl bg-white p-4 [box-shadow:14px_17px_40px_4px_#7090B014] dark:bg-navy-800">
      <TooltipHorizon
        content={label}
        placement="top"
        extra="border border-gray-200 dark:border-gray-700"
        trigger=<div className="truncate text-xs font-medium text-[#2B3674] dark:text-white">
          {label}
        </div>
      ></TooltipHorizon>
      <div className="relative left-1/2 top-5 h-3/4 w-3/4 -translate-x-1/2">
        <CircularProgressbarWithChildren
          value={value}
          circleRatio={0.5}
          strokeWidth={5}
          styles={buildStyles({
            rotation: -0.25,
            strokeLinecap: 'round',
            textSize: '16px',
            pathTransitionDuration: 0.5,
            pathColor: 'var(--color-500)',
            textColor: '#1B2559',
            trailColor: '#E9EDF7',
            backgroundColor: 'var(--color-500)',
          })}
        >
          <div className="mt-12 whitespace-nowrap text-2xl font-bold text-[#2B3674] dark:text-white">
            {value + '%'}
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  )
}

export default MetricsCard
