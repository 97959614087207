import React, { useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useTable } from 'react-table'
import Card from 'components/card'
import { cn } from 'utils/cn'
import LineChart from 'components/charts/LineChart'
import { lineChartOptionsMiniArea1 } from 'variables/charts'
import ConfixaConstants from 'common/config/confixaConstants'
const { NO_DATA } = ConfixaConstants

/**
 * Renders a metrics table with provided columns and data.
 *
 * @param {Array} columnsData - Data for the columns of the table
 * @param {Array} tableData - Data to populate the table
 * @param {boolean} isAPILoading - Flag indicating if API data is still loading
 * @return {JSX.Element} The rendered metrics table
 */
const MetricsTable = ({ columnsData, tableData, isAPILoading }) => {
  const columns = useMemo(() => columnsData, [columnsData])
  const [data, setData] = useState([])
  const tableSkeletons = Array.from({ length: 2 })
  useEffect(() => {
    setData(tableData)
  }, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance
  return (
    <Card extra="px-6 py-3 !mt-0 h-full w-full">
      <div className="mt-4 h-full w-full overflow-y-hidden overflow-x-scroll 2xl:overflow-x-hidden">
        {isAPILoading ? (
          <div className="flex flex-col">
            {tableSkeletons.map((_item) => (
              <div className="flex animate-pulse border-gray-200 py-4 dark:!border-white/10">
                <div className="w-1/5">
                  <div className="ml-4 h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-1/5">
                  <div className="ml-4 h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-1/5">
                  <div className="ml-4 h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-1/5">
                  <div className="ml-4 h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-1/5">
                  <div className="ml-4 h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <table
              {...getTableProps()}
              className="w-full font-dm text-navy-700"
            >
              <thead className="w-full">
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    className="items-center text-base"
                    {...headerGroup.getHeaderGroupProps()}
                    key={index}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        className="pb-[15px] text-start text-sm font-bold tracking-wide text-gray-600"
                        key={index}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {tableData?.length > 0 && (
                <tbody {...getTableBodyProps()} className="w-full">
                  {page.map((row, index) => {
                    prepareRow(row)
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={index}
                        className="items-center"
                      >
                        {row.cells.map((cell, index) => {
                          let dataContent = row.original
                          switch (cell.column.Header) {
                            case 'Name':
                              dataContent = (
                                <div className="text-xs font-bold text-blueSecondary dark:text-white">
                                  {dataContent.serviceName}
                                </div>
                              )
                              break
                            case 'Transaction Type':
                              dataContent = (
                                <div className="text-xs text-gray-10 dark:text-white">
                                  {dataContent.transactionType}
                                </div>
                              )
                              break
                            case 'Environment':
                              dataContent = (
                                <div
                                  className={cn(
                                    'h-fit w-fit justify-center rounded-[7px] px-2 font-medium capitalize',
                                    {
                                      'bg-[#FFD5001A] text-[#FFA657] dark:bg-[#C9FBD50d]':
                                        dataContent.environments ===
                                        'development',
                                    }
                                  )}
                                >
                                  {dataContent.environments}
                                </div>
                              )
                              break
                            case 'Agent Name':
                              dataContent = (
                                <div className="text-xs text-gray-10 dark:text-white">
                                  {dataContent.agentName}
                                </div>
                              )
                              break
                            case 'Latency (avg.)':
                              dataContent = (
                                <div className="text-xs text-gray-10 dark:text-white">
                                  {(dataContent?.latency?.value != null &&
                                    dataContent?.latency?.value?.toFixed(2) +
                                      'ms') ||
                                    dataContent?.latency + 'ms' ||
                                    'N/A'}
                                </div>
                              )
                              break
                            case 'Throughput':
                              dataContent = (
                                <div className="flex h-14 w-1/2 items-center justify-between">
                                  <div className="whitespace-nowrap text-xs text-gray-10 dark:text-white">
                                    {dataContent.throughput.value
                                      ? dataContent.throughput.value.toFixed(
                                          2
                                        ) + ' tpm'
                                      : 'N/A'}
                                  </div>
                                  <div className="h-20 w-28">
                                    <LineChart
                                      chartData={
                                        dataContent.throughput.coordinates || []
                                      }
                                      chartOptions={lineChartOptionsMiniArea1}
                                    />
                                  </div>
                                </div>
                              )
                              break
                            case 'Failed Transaction Rate':
                              dataContent = (
                                <div className="text-xs text-gray-10 dark:text-white">
                                  {dataContent.transactionErrorRate.value !==
                                  undefined
                                    ? dataContent.transactionErrorRate.value +
                                      ' %'
                                    : 'N/A'}
                                </div>
                              )
                              break
                            default:
                              dataContent = null
                          }
                          return (
                            <td
                              className="mt-[20px] lg:pr-2"
                              {...cell.getCellProps()}
                              key={index}
                            >
                              {dataContent}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              )}
            </table>
            {tableData?.length === 0 && (
              <div className="text-md mt-5 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                {NO_DATA}
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  )
}

export default MetricsTable
