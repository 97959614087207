import AddProject from './component/AddProject'

/**
 * Renders the CreateProject component.
 *
 * @return {JSX.Element} The rendered CreateProject component.
 */
const CreateProject = () => {
  return (
    <>
      <div className="mt-5 h-full w-full gap-5 rounded-[20px]">
        <div className="col-span-12 w-full 3xl:col-span-4">
          <AddProject />
        </div>
      </div>
    </>
  )
}

export default CreateProject
