import Card from 'components/card'
import BillingDetails from './components/BillingDetails'
import { useState, useEffect } from 'react'
import ConfixaConstants from 'common/config/confixaConstants'
import { getOneBillingData } from 'common/commonFunction'
import moment from 'moment'
const { Billing, SUBSCRIPTION_EXPIRED, EMAIL, BUTTON_TEXT } = ConfixaConstants

function CoursePage() {
  const [toggleState, setToggleState] = useState(Billing.BILLING_TABS_TEXT)
  const [currentDomainPage, setCurrentDomainPage] = useState(0)
  const [billingData, setBillingData] = useState('')
  const [loading, setLoading] = useState(false)
  const billingSkeletons = Array.from({ length: 2 })

  const domainName = window.location.hostname

  const toggleTab = (tab) => {
    setToggleState(tab)
  }

  useEffect(() => {
    storeBillingData()
  }, [])

  const storeBillingData = async () => {
    setLoading(true)
    try {
      const responseData = await getOneBillingData(domainName)
      if (responseData) {
        setBillingData(responseData)
      } else {
        console.error('Data returned from API is not valid:', responseData)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching billing data:', error)
    }
  }

  const deactivatedDate = billingData?.deactivatesOn
    ? moment(billingData?.deactivatesOn).format(' Do MMMM YYYY')
    : ''
  const activatedDate = billingData?.activatedOn
    ? moment(billingData?.activatedOn).format(' Do MMMM YYYY')
    : ''
  const emailBody = encodeURIComponent(
    EMAIL.BODY.replace('{deactivateDate}', deactivatedDate)
      .replace('{activateDate}', activatedDate)
      .replace('{deactivateDate}', deactivatedDate)
  )

  return (
    <div className="w-full grid-cols-1 gap-5 font-dm lg:grid-cols-12">
      <div className="h-full w-full rounded-[20px] lg:col-span-12 4xl:col-span-12">
        <div className="mb-1 w-full rounded-xl xl:mb-3">
          <div className="mb-1 flex w-full flex-row gap-8 overflow-hidden text-3xl">
            <div
              className={
                toggleState === Billing.BILLING_TABS_TEXT
                  ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
                  : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
              }
              onClick={() => toggleTab(Billing.BILLING_TABS_TEXT)}
            >
              <p
                className={
                  toggleState === Billing.BILLING_TABS_TEXT
                    ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                    : 'text-[18px] text-disableGray dark:text-white'
                }
              >
                {Billing.BILLING_TABS_TEXT}
              </p>
            </div>
          </div>
        </div>
        <Card extra={'w-full h-full bg-white mt-3'}>
          {loading ? (
            <div className="flex h-[200px] animate-pulse flex-col gap-y-8 p-4">
              <div className="flex flex-col gap-y-4">
                {billingSkeletons.map((_item, index) => (
                  <div key={index} className="flex gap-x-4">
                    <div className="mt-4 flex w-full flex-col gap-y-2">
                      <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                      <div className="h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                    <div className="mt-4 flex w-full flex-col gap-y-2">
                      <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                      <div className="h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </div>
                ))}
                <div className="mt-8 flex w-full flex-col gap-y-2">
                  <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                  <div className="h-2 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
            </div>
          ) : (
            <BillingDetails
              toggleState={toggleState}
              setToggleState={setToggleState}
              currentPage={currentDomainPage}
              billingData={billingData}
              onPageChange={(pageNumber) => {
                setCurrentDomainPage(pageNumber)
              }}
            />
          )}
        </Card>
      </div>
      {loading ? (
        <Card extra="w-full h-full bg-white mt-3 animate-pulse">
          <div className="flex h-[200px] flex-col gap-y-8 p-4">
            <div className="flex flex-col gap-y-4">
              <div className="flex gap-x-4">
                <div className="mt-4 flex w-full flex-col gap-y-2">
                  <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                  <div className="h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="mt-4 flex w-full flex-col gap-y-2">
                  <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                  <div className="h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
              <div className="mt-8 flex w-full flex-col gap-y-2">
                <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                <div className="h-2 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        billingData?.expiringBy <= 10 && (
          <Card extra="w-full h-full bg-white mt-3">
            <div className="h-[50%] w-full rounded-2xl bg-white p-8 dark:!bg-navy-800">
              <span className="text-3xl font-bold text-[#FF5652]">
                {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_ALERT}
              </span>
              <div className="pt-5">
                <span className="text-xl font-semibold text-[#1B2559] dark:text-white">
                  {billingData?.expiringBy}{' '}
                  {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_DAYS_TOPIC}
                </span>
              </div>
              <div className="container mx-auto py-4">
                <div className="mb-4">
                  <span className="block text-sm md:text-base lg:text-lg"></span>
                  <div className="flex flex-col items-center justify-between md:flex-row">
                    <span className="mb-4 text-sm dark:text-white md:mb-0 md:text-base lg:text-lg">
                      {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_END_TOPIC}
                      <br />
                      {SUBSCRIPTION_EXPIRED.RENEW_TOPIC}
                    </span>
                    <button
                      onClick={() =>
                        (window.location.href = `mailto:${SUBSCRIPTION_EXPIRED.CONTACT_EMAIL}?subject=${EMAIL.SUBJECT}&body=${emailBody}`)
                      }
                      className="w-full rounded-lg bg-brand-500 px-4 py-2 text-center font-medium text-white md:w-[16%]"
                    >
                      {BUTTON_TEXT.RENEW}
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex w-full rounded-lg bg-gray-100 px-4 py-2">
                <div className="font-semibold text-[#1B2559]">Note :</div>
                <div className="pl-2 dark:text-black">
                  {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_NOTE}
                  <div>
                    {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_CONTACT_NOTE}{' '}
                    <a
                      href={`mailto:${SUBSCRIPTION_EXPIRED.CONTACT_EMAIL}?subject=${EMAIL.SUBJECT}&body=${emailBody}`}
                      className="font-semibold text-brand-500"
                    >
                      {SUBSCRIPTION_EXPIRED.CONTACT_EMAIL}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )
      )}
    </div>
  )
}

export default CoursePage
