import { useDropzone } from 'react-dropzone'
// Assets
import React from 'react'

const DropZonefile = (props) => {
  const { content, onDrop, registrationProps, accept, errors, multiple } = props
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop: onDrop,
    errors: errors,
    multiple: multiple,
  })
  return (
    <div
      className="flex h-full w-full cursor-pointer items-center justify-center rounded-xl border-dashed border-navy-700"
      {...getRootProps({ className: 'dropzone' })}
    >
      <input {...getInputProps()} {...registrationProps} />
      <button className="h-full !w-full">{content}</button>
    </div>
  )
}

export default DropZonefile
