export const tableColumnsManagement = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Environment',
    accessor: 'environment',
  },
  {
    Header: 'Latency (avg.)',
    accessor: 'latency',
  },
  {
    Header: 'Throughput',
    accessor: 'throughput',
  },
  {
    Header: 'Failed Transaction Rate',
    accessor: 'failedTransactionRate',
  },
]
