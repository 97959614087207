export const projectColumnsDataOrders = [
  {
    Header: 'S.NO',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'PROJECT NAME',
    accessor: 'name',
  },
  {
    Header: 'ACTIONS',
    accessor: 'ORDER ACTIONS',
    disableSortBy: true,
  },
]
