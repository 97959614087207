import TooltipHorizon from 'components/tooltip'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BsArrowDown, BsArrowRight, BsArrowUp } from 'react-icons/bs'
import { cn } from 'utils/cn'

const CostCard = ({
  cardTitle,
  cardValue,
  cardValueChange,
  changeDirection,
  cardSubText,
  cardBtnText,
  cardBtnClickHandler,
  cardTooltipContent,
  extraClass,
}) => {
  return (
    <div
      className={`flex flex-col gap-3 bg-white p-4 [box-shadow:14px_17px_40px_4px_#7090B014] dark:bg-navy-800 ${extraClass}`}
    >
      <div className="flex items-center gap-2">
        <div className="text-[14px] font-bold">{cardTitle}</div>
        <TooltipHorizon
          content={cardTooltipContent}
          placement="bottom"
          extra="border border-gray-200 dark:border-gray-700 !w-72"
          trigger=<div>
            <AiOutlineInfoCircle fill="#589CFF" />
          </div>
        ></TooltipHorizon>
      </div>

      <div className="flex flex-wrap items-center justify-between">
        <div className="text-[30px] font-bold">{cardValue}</div>
        {cardValueChange && (
          <div
            className={cn(
              'flex h-fit items-center justify-between gap-1 rounded-[7px] px-2 font-medium',
              {
                'bg-[#C9FBD580] text-horizonGreen-500 dark:bg-[#C9FBD50d]':
                  changeDirection === 'down',
                'bg-[#EE5D501A] text-[#EE5D50]': changeDirection === 'up',
              }
            )}
          >
            {changeDirection === 'up' ? (
              <BsArrowUp fill="#EE5D50" />
            ) : (
              <BsArrowDown fill="#01B574" />
            )}
            {cardValueChange}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <div className="text-xs font-medium text-gray-20 dark:text-gray-600">
          {cardSubText}
        </div>

        {cardBtnText && (
          <button
            onClick={cardBtnClickHandler}
            className="flex w-fit items-center justify-between gap-2 text-sm font-bold text-blueSecondary transition hover:translate-x-1 dark:text-white"
          >
            {cardBtnText} <BsArrowRight strokeWidth={0.5} />
          </button>
        )}
      </div>
    </div>
  )
}

export default CostCard
