export const costCardsData = [
  {
    cardTitle: 'Kubernetes Cost',
    cardValue: 'US$36.98',
    cardValueChange: '75.00%',
    changeDirection: 'down',
    cardSubText: 'Including 1 cluster',
    cardBtnText: 'View report',
    cardBtnClickHandler: () => {
      window.open(
        'https://kubecost.confixa.com/allocations?window=7d',
        '_blank'
      )
    },
    cardTooltipContent:
      'Estimated cost of monitored kubernetes clusters based on last 7 days of resource consumption. Does not include external cloud costs.',
    extraClass: 'grow basis-[24.5%] rounded-none 2xl:rounded-l-[30px]',
  },
  {
    cardTitle: 'Total Cost',
    cardValue: 'US$36.98',
    cardValueChange: '25.00%',
    changeDirection: 'up',
    cardSubText: 'All Cloud Cost',
    cardBtnText: 'View report',
    cardBtnClickHandler: () => {
      window.open(
        'https://kubecost.confixa.com/allocations?window=7d',
        '_blank'
      )
    },
    cardTooltipContent: 'The past 7 days of all known cloud costs',
    extraClass: 'grow basis-[24.5%]',
  },
  // {
  //   cardTitle: 'Possible Monthly Savings',
  //   cardValue: 'US$298.28/mo',
  //   cardSubText: 'See Recommendations',
  //   cardBtnText: 'View report',
  //   cardBtnClickHandler: () => {
  //     window.open('https://kubecost.confixa.com/savings', '_blank')
  //   },
  //   cardTooltipContent:
  //     'Estimated monthly savings are probability adjusted & include both Kubernetes & external cloud insights.',
  //   extraClass: 'grow basis-[24.5%]',
  // },
  // {
  //   cardTitle: 'Cluster Efficiency',
  //   cardValue: '15.9%',
  //   cardSubText: 'Including 1 cluster',
  //   cardTooltipContent:
  //     'Percentage of cluster resources, weighed by cost, used over the last 7 days. This is defined as cost of CPU/RAM/GPU/Storage usage divided by total spent running the cluster',
  //   extraClass: 'grow basis-[24.5%] rounded-none 2xl:rounded-r-[30px]',
  // },
]

export const metricCardsData = [
  {
    cardTitle: 'CPU Utilisation',
    percentage: '5.92',
  },
  {
    cardTitle: 'CPU Request Utilisation',
    percentage: '6.73',
  },
  {
    cardTitle: 'CPU Limit Utilisation',
    percentage: '5.42',
  },
  {
    cardTitle: 'Memory Utilisation',
    percentage: '21',
  },
  {
    cardTitle: 'Memory Request Utilisation',
    percentage: '13.5',
  },
  {
    cardTitle: 'Memory Limit Utilisation',
    percentage: '11.1',
  },
]

export const serviceUptimeData = {
  projectName: 'Confixa',
  data: [
    {
      serviceName: 'confixa-api',
      uptimePercentage: 100,
      fromDate: '2024-03-12T00:00:00',
      data: [
        {
          date: '2024-03-09T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-10T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-11T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-12T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-13T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-14T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-15T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-16T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-17T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-18T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-19T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-20T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-21T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-22T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-23T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-24T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-25T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-26T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-27T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-28T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-29T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-30T00:00:00',
          status: 'up',
        },
        {
          date: '2024-03-31T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-01T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-02T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-03T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-04T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-05T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-06T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-07T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-08T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-09T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-10T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-11T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-12T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-13T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-14T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-15T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-16T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-17T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-18T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-19T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-20T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-21T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-22T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-23T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-24T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-25T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-26T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-27T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-28T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-29T00:00:00',
          status: 'up',
        },
        {
          date: '2024-04-30T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-01T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-02T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-03T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-04T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-05T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-06T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-07T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-08T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-09T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-10T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-11T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-12T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-13T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-14T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-15T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-16T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-17T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-18T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-19T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-20T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-21T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-22T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-23T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-24T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-25T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-26T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-27T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-28T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-29T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-30T00:00:00',
          status: 'up',
        },
        {
          date: '2024-05-31T00:00:00',
          status: 'up',
        },
        {
          date: '2024-06-01T00:00:00',
          status: 'up',
        },
        {
          date: '2024-06-02T00:00:00',
          status: 'up',
        },
        {
          date: '2024-06-03T00:00:00',
          status: 'up',
        },
        {
          date: '2024-06-04T00:00:00',
          status: 'up',
        },
        {
          date: '2024-06-05T00:00:00',
          status: 'up',
        },
        {
          date: '2024-06-06T00:00:00',
          status: 'up',
        },
      ],
    },
    {
      serviceName: 'confixa-frontend',
      uptimePercentage: 99,
      fromDate: '2024-03-09T00:00:00',
      data: [
        {
          date: '2024-03-09T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-10T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-11T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-12T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-13T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-14T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-15T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-16T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-17T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-18T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-19T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-20T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-21T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-22T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-23T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-24T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-25T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-26T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-27T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-28T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-29T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-30T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-03-31T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-01T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-02T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-03T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-04T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-05T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-06T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-07T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-08T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-09T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-10T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-11T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-12T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-13T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-14T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-15T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-16T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-17T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-18T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-19T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-20T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-21T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-22T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-23T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-24T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-25T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-26T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-27T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-28T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-29T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-04-30T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-01T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-02T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-03T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-04T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-05T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-06T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-07T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-08T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-09T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-10T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-11T00:00:00Z',
          status: 'down',
        },
        {
          date: '2024-05-12T00:00:00Z',
          status: 'down',
        },
        {
          date: '2024-05-13T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-14T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-15T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-16T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-17T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-18T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-19T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-20T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-21T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-22T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-23T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-24T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-25T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-26T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-27T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-28T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-29T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-30T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-05-31T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-06-01T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-06-02T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-06-03T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-06-04T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-06-05T00:00:00Z',
          status: 'up',
        },
        {
          date: '2024-06-06T00:00:00Z',
          status: 'up',
        },
      ],
    },
  ],
}
