import React, { useState } from 'react'
import { useEffect } from 'react'
import { storeUserInfo } from 'common/commonFunction'
import CostCard from './components/CostCard'
import MetricsCard from './components/MetricsCard'
import MetricsTable from './components/MetricsTable'
import { tableColumnsManagement } from './components/tableColumnsManagement'
import ServiceUptimeStatusCard from './components/ServiceUptimeStatusCard'
import { serviceUptimeData } from './variables/mockData'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import ConfixaConstants from 'common/config/confixaConstants'
import { useToaster } from 'common/Toaster'
import { metricsCardLabelMapping } from './variables/homeConstants'

const apiService = ApiCaller()
const { TOAST } = ConfixaConstants

/**
 * This function represents the Dashboard component, which fetches application data and displays it in different tables.
 *
 * @return {JSX.Element} The JSX for the Dashboard component
 */
const Dashboard = () => {
  const { addToast } = useToaster()
  const [metricError, setMetricError] = useState(false)
  const [isKubeCostAPILoading, setKubeCostAPILoading] = useState(false)
  const [metricCardsData, setMetricCardsData] = useState([])
  const metricsCardSkeletons = Array.from({ length: 6 })
  const [metricTableData, setMetricTableData] = useState([])
  const [kubeCostCardData, setKubeCostCardDataData] = useState([])
  const [isMetricTableDataAPILoading, setMetricTableDataAPILoading] =
    useState(false)
  const kubecostUrl = `${process.env.REACT_APP_KUBECOST_URL}allocations?window=7d`
  const showMetricsCard = process.env.REACT_APP_SHOW_METRICS_CARD
  const [arrayLength, setArrayLength] = useState(2)

  const getKubecostCardSkeletons = (length) => {
    return Array.from({ length })
  }

  let kubecostCardSkeletons = getKubecostCardSkeletons(arrayLength)

  useEffect(() => {
    storeUserInfo()
    const getK8sMetrics = async () => {
      try {
        const response = await apiService.apiCall(
          'get',
          apiConfig.GET_K8S_METRICS
        )
        if (response?.data?.code === 200 || response?.data?.code === 204) {
          setMetricCardsData(response?.data?.data)
        } else {
          setMetricError(true)
          addToast({
            title: response?.data?.msg,
            type: 'error',
          })
        }
      } catch (error) {
        setMetricError(true)
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    }

    if (!metricError) {
      getK8sMetrics()
    }
  }, [addToast, metricError])

  useEffect(() => {
    if (showMetricsCard === 'true') {
      getMatricTableData()
    }
    getKubeConstData()
  }, [])

  const kubConstDataPrepare = (
    kubeConst,
    totalConst,
    possibleMonthlySavings,
    clusterEfficiency
  ) => {
    const kubeCardsData = [
      {
        cardTitle: 'Kubernetes Cost',
        cardValue: `US$${kubeConst?.total_cost}`,
        cardValueChange:
          parseInt(kubeConst?.percentage) > 0
            ? `${kubeConst?.percentage}%`
            : '',
        changeDirection: `${kubeConst?.type === 'increased' ? 'up' : 'down'}`,
        cardSubText: 'Including 1 cluster',
        cardBtnText: 'View report',
        cardBtnClickHandler: () => {
          window.open(kubecostUrl, '_blank')
        },
        cardTooltipContent:
          'Estimated cost of monitored kubernetes clusters based on last 7 days of resource consumption. Does not include external cloud costs.',
        extraClass: 'grow basis-[24.5%] rounded-none 2xl:rounded-l-[30px]',
      },
      {
        cardTitle: 'Total Cost',
        cardValue: `US$${totalConst?.total_cost}`,
        cardValueChange:
          parseInt(totalConst?.percentage) > 0
            ? `${totalConst?.percentage}%`
            : '',
        changeDirection: `${totalConst.type === 'increased' ? 'up' : 'down'}`,
        cardSubText: 'All Cloud Cost',
        cardBtnText: 'View report',
        cardBtnClickHandler: () => {
          window.open(kubecostUrl, '_blank')
        },
        cardTooltipContent: 'The past 7 days of all known cloud costs',
        extraClass: `grow basis-[24.5%] ${
          Object.keys(possibleMonthlySavings).length > 0
            ? ''
            : 'rounded-none 2xl:rounded-r-[30px]'
        }`,
      },
    ]

    if (
      possibleMonthlySavings !== null &&
      possibleMonthlySavings !== undefined &&
      Object.keys(possibleMonthlySavings).length > 0
    ) {
      const PossibleMonthlySavings = {
        cardTitle: 'Possible Monthly Savings',
        cardValue: `US$${possibleMonthlySavings?.total_cost}/mo`,
        cardSubText: 'See Recommendations',
        cardBtnText: 'View report',
        cardBtnClickHandler: () => {
          window.open(`${process.env.REACT_APP_KUBECOST_URL}savings`, '_blank')
        },
        cardTooltipContent:
          'Estimated monthly savings are probability adjusted & include both Kubernetes & external cloud insights.',
        extraClass: `grow basis-[24.5%] ${
          Object.keys(clusterEfficiency).length > 0
            ? ''
            : 'rounded-none 2xl:rounded-r-[30px]'
        }`,
      }
      setArrayLength(3)
      kubeCardsData.push(PossibleMonthlySavings)
    }
    if (
      clusterEfficiency !== null &&
      clusterEfficiency !== undefined &&
      Object.keys(clusterEfficiency).length > 0
    ) {
      const ClusterEfficiency = {
        cardTitle: 'Cluster Efficiency',
        cardValue:
          parseInt(clusterEfficiency?.percentage) > 0
            ? `${clusterEfficiency?.percentage}%`
            : '',
        cardSubText: 'Including 1 cluster',
        cardTooltipContent:
          'Percentage of cluster resources, weighed by cost, used over the last 7 days. This is defined as cost of CPU/RAM/GPU/Storage usage divided by total spent running the cluster',
        extraClass: `grow basis-[24.5%] ${
          Object.keys(clusterEfficiency).length > 0
            ? 'rounded-none 2xl:rounded-r-[30px]'
            : ''
        }`,
      }
      setArrayLength(4)
      kubeCardsData.push(ClusterEfficiency)
    }
    setKubeCostCardDataData(kubeCardsData)
  }

  const getKubeConstData = async () => {
    try {
      setKubeCostAPILoading(true)
      const response = await apiService.apiCall(
        'get',
        apiConfig.GET_KUBE_COST_DATA
      )
      if (response?.data?.code === 200 || response?.data?.code === 204) {
        const data = response?.data?.data
        kubConstDataPrepare(
          data?.kubernetesCosts,
          data?.totalCosts,
          data?.possibleMonthlySavings,
          data?.clusterEfficiency
        )
      }
      setKubeCostAPILoading(false)
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  /**
   * Retrieves metric table data from the API based on the current time.
   *
   * @return {Promise<void>} - A promise that resolves when the metric table data is successfully retrieved or an error toast is displayed.
   */
  const getMatricTableData = async () => {
    setMetricTableDataAPILoading(true)
    const currentTime = new Date()
    const endTime = currentTime.toISOString()
    const startTime = new Date(
      currentTime.getTime() - 15 * 60 * 1000
    ).toISOString()
    try {
      const url = apiConfig.GET_ELASTIC_DATA.replace(
        ':startTime',
        encodeURIComponent(startTime)
      ).replace(':endTime', encodeURIComponent(endTime))
      const response = await apiService.apiCall('get', url)
      if (response?.data?.code === 200 || response?.data?.code === 204) {
        setMetricTableData(response.data.data)
      }
      setMetricTableDataAPILoading(false)
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  return (
    <>
      <div className="flex animate-fade-in flex-col gap-8">
        <div className="flex flex-wrap gap-[1px] dark:text-white">
          {isKubeCostAPILoading
            ? kubecostCardSkeletons.map((_data, index) => (
                <div
                  key={index}
                  className={`flex h-[162px] grow basis-[24.5%] flex-col gap-3 rounded-none bg-white p-4 [box-shadow:14px_17px_40px_4px_#7090B014] dark:bg-navy-800 ${
                    index === 0
                      ? '2xl:rounded-l-[30px]'
                      : '2xl:rounded-r-[30px]'
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <div className="h-4 w-28 self-center rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                    <div className="rounded-full bg-gray-200 p-2 dark:bg-gray-800"></div>
                  </div>

                  <div className="mt-6 flex flex-wrap items-center justify-between">
                    <div className="h-8 w-28 self-start rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                    <div className="h-8 w-28 self-end rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  </div>

                  <div className="mt-6 flex flex-col gap-1">
                    <div className="h-2 w-32 self-start rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                    <div className="h-2 w-32 self-start rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                </div>
              ))
            : kubeCostCardData.map((card, index) => (
                <CostCard
                  key={index}
                  cardTitle={card.cardTitle}
                  cardValue={card.cardValue}
                  cardValueChange={card.cardValueChange}
                  changeDirection={card.changeDirection}
                  cardSubText={card.cardSubText}
                  cardBtnText={card.cardBtnText}
                  cardBtnClickHandler={card.cardBtnClickHandler}
                  cardTooltipContent={card.cardTooltipContent}
                  extraClass={card.extraClass}
                />
              ))}
        </div>

        {showMetricsCard === 'true' && (
          <div className="grid auto-rows-[150px] grid-cols-[repeat(auto-fit,_150px)] grid-rows-[150px] justify-between gap-x-6 gap-y-8 dark:text-white">
            {metricCardsData?.length
              ? metricCardsData.map((data, index) => (
                  <MetricsCard
                    key={index}
                    label={metricsCardLabelMapping[data.label]}
                    value={data.value}
                  />
                ))
              : metricsCardSkeletons.map((_data, index) => (
                  <div
                    key={index}
                    className="flex animate-pulse flex-col rounded-2xl bg-white p-4 shadow-lg dark:bg-navy-800"
                  >
                    <div className="mb-4 h-2 w-32 self-start rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                    <div className="h-16 w-28 self-center rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                    <div className="mt-2 h-4 w-28 self-center rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                ))}
          </div>
        )}

        <MetricsTable
          tableData={metricTableData}
          columnsData={tableColumnsManagement}
          isAPILoading={isMetricTableDataAPILoading}
        />

        {process.env.REACT_APP_SHOW_SERVICE_UPTIME_STATUS_CARD === 'true' && (
          <ServiceUptimeStatusCard uptimeData={serviceUptimeData} />
        )}
      </div>
    </>
  )
}

export default Dashboard
