import React, { useState, useEffect } from 'react'
import NewChat from './components/NewChat'
import ElasticSearch from './components/ElasticSearch'
import Chats from './components/Chats'
import PreviouslyData from './components/PreviouslyData'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useLocation, useNavigate } from 'react-router-dom'
import { useToaster } from 'common/Toaster'
import ConfixaConstants from 'common/config/confixaConstants'
import { decryptFunction } from 'common/commonFunction'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { LuExternalLink } from 'react-icons/lu'

const { TOAST } = ConfixaConstants
const apiService = ApiCaller()

/**
 * Initializes the ChatContainer component with state variables, API calls, and useEffect hooks for data management.
 *
 * @return {JSX.Element} The JSX element representing the ChatContainer component
 */
function ChatContainer({ isVisible, onValueChange }) {
  const [receivedData, setReceivedData] = useState([])
  const [topicsData, setTopicsData] = useState([])
  const [inputData, sentInput] = useState('')
  const [previousData, setPreviousData] = useState([])
  const [AllTopicsPreviousData, setAllTopicsPreviousData] = useState([])
  const [PreviouslyInsideData, setPreviouslyInsideData] = useState()
  const [responseData, setResponseData] = useState('')
  const [storedValue, setStoredValue] = useState()
  const [isLoading, setIsLoading] = useState()
  const [fullScreen, setFullScreen] = useState(false)
  const [buttonClick, getButtonClick] = useState(false)
  const location = useLocation()
  const { addToast } = useToaster()
  let navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const userId = searchParams.get('id')
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  const showChatBoat = process.env.REACT_APP_SHOW_CHAT_BOAT

  if (showChatBoat !== 'true') {
    navigate('/')
  }

  useEffect(() => {
    setStoredValue(null)
    getPreviouslyData()
    checkAndCallGetPreviouslyInsideData()
    if (buttonClick) {
      setPreviouslyInsideData(null)
      setStoredValue(null)
    }
    //eslint-disable-next-line
  }, [userId, buttonClick])

  useEffect(() => {
    if (receivedData && receivedData.length > 0) {
      sentChatData()
    }
    //eslint-disable-next-line
  }, [receivedData])
  useEffect(() => {
    if (!isVisible) getButtonClick(true)
    //eslint-disable-next-line
  }, [isVisible])

  /**
   * Sets the previously topic data, updates the state with the new data,
   * and calls the getPreviouslyInsideData function with the new data.
   *
   * @param {Object} data - The previously topic data to be set.
   * @return {void} This function does not return anything.
   */
  const previouslyTopicData = (data) => {
    setIsLoading(false)
    setPreviouslyInsideData(data)
    getPreviouslyInsideData(data)
  }

  /**
   * Calls the PreviouslyDeletedData function with the provided setPreviouslyDeletedData parameter.
   *
   * @param {function} setPreviouslyDeletedData - The function to set previously deleted data.
   */
  const previouslyDeletedTopicData = (setPreviouslyDeletedData) => {
    PreviouslyDeletedData(setPreviouslyDeletedData)
  }

  /**
   * Update the stored value with a new value.
   *
   * @param {type} newValue - The new value to be stored
   * @return {type} undefined
   */
  const updateStoredValue = (newValue) => {
    setStoredValue(newValue)
  }

  /**
   * Sets the received data from the child component.
   *
   * @param {any} data - The data received from the child component.
   * @return {void} This function does not return anything.
   */
  const receiveDataFromChild = (data) => {
    setReceivedData(data)
  }
  /**
   * A function that updates the previous data with the provided topicsData.
   *
   * @param {type} topicsData - The data to update the previous data with
   * @return {type} undefined
   */
  const updatePreviousData = (topicsData) => {
    setAllTopicsPreviousData(topicsData)
  }

  /**
   * Check if the value represents a button click, and perform certain actions if it does.
   *
   * @param {any} value - The value to be checked
   * @return {undefined} This function does not return any value
   */
  const isButtonClick = (value) => {
    getButtonClick(value)
    if (value === true) {
      const currentUrl = new URL(window.location.href)
      currentUrl.searchParams.delete('id')
      navigate(currentUrl.pathname + currentUrl.search)
    }
  }
  /**
   * Check and call the getPreviouslyInsideData function if the userId is truthy.
   */
  const checkAndCallGetPreviouslyInsideData = () => {
    if (userId) {
      try {
        const decryptedTopicId = decryptFunction(userId)
        const topicIdInt = parseInt(decryptedTopicId)
        getPreviouslyInsideData(topicIdInt)
      } catch (error) {
        console.error('Error decrypting topic ID:', error)
      }
    }
  }
  /**
   * Retrieves previously stored data from the API.
   *
   * @return {Promise<void>} - A promise that resolves when the data is retrieved successfully.
   */
  const getPreviouslyData = async () => {
    const apiUrl = apiConfig.PREVIOUSLY_DATA
    try {
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        setPreviousData(response?.data?.data)
      } else if (response?.data?.code === 204) {
        setPreviousData([])
        if (fullScreen) {
          navigate('/chatbot')
          isButtonClick(true)
        }
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }
  /**
   * Sends chat data to the API and retrieves the response.
   *
   * @return {Promise<void>} - A promise that resolves when the chat data is sent and the response is received.
   */
  const sentChatData = async () => {
    const apiUrl = apiConfig.CHAT_DATA
    const payloadData = {
      userQuestion: inputData,
      organizationId: organizationData?.id,
    }
    if (storedValue) {
      payloadData.topicId = storedValue
    } else if (PreviouslyInsideData) {
      payloadData.topicId = PreviouslyInsideData
    }
    try {
      setIsLoading(true)
      const response = await apiService.apiCall('post', apiUrl, payloadData)
      if (response?.data?.code === 200) {
        getPreviouslyData()
        setResponseData(response?.data?.data)
        if (buttonClick) {
          setPreviouslyInsideData(response?.data?.data?.topicId)
        }
        getButtonClick(false)
      } else {
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Retrieves previously inside data from the API.
   *
   * @param {string} PreviouslyInsideData - The ID of the previously inside data.
   * @return {Promise<void>} A promise that resolves when the data is fetched and processed.
   */
  const getPreviouslyInsideData = async (PreviouslyInsideData) => {
    const apiUrl = apiConfig.PREVIOUSLY_INSIDE_DATA.replace(
      ':topic_id',
      PreviouslyInsideData
    )
    try {
      if (!PreviouslyInsideData) {
        return
      }
      setIsLoading(true)
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200 || response?.data?.code === 204) {
        setTopicsData(response?.data?.data)
        updatePreviousData(response?.data?.data)
      } else {
        console.error('Error: Unexpected response code', response?.data?.code)
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Deletes previously saved data.
   *
   * @param {string} PreviouslyDeleteInsideData - The ID of the data to be deleted.
   * @return {Promise<void>} - A promise that resolves when the data is successfully deleted.
   */
  const PreviouslyDeletedData = async (PreviouslyInsideData) => {
    const apiUrl = apiConfig.DELETE_DATA.replace(
      ':topic_id',
      PreviouslyInsideData
    )
    try {
      const response = await apiService.apiCall('delete', apiUrl)
      if (response?.data?.code === 200) {
        getPreviouslyData()
        if (fullScreen) {
          if (location.pathname === `/chatbot/${PreviouslyInsideData}`) {
            navigate('/chatbot')
            isButtonClick(true)
          }
        }
        addToast({
          title: TOAST.MESSAGES.SUCCESS.deletedData,
          type: 'success',
        })
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  /**
   * A function that handles the click event.
   *
   * @return {void} - Does not return anything.
   */
  const handleClick = () => {
    setFullScreen(false)
    onValueChange(!isFinite) // Invoke parent callback with value
  }

  /**
   * Handles the full screen functionality.
   *
   * @return {void}
   */
  const handlefullScreen = () => {
    PreviouslyInsideData
      ? navigate(`/chatbot/${PreviouslyInsideData}`)
      : navigate('/chatbot')
    setFullScreen(true)
    onValueChange(false)
  }

  useEffect(() => {
    const value = location.pathname.includes('/chatbot')
    if (value) {
      setFullScreen(true)
    } else {
      setFullScreen(false)
    }
  }, [location])

  return (
    <div
      className={`${
        fullScreen
          ? 'bottom-[20px] flex grid-cols-7 flex-col justify-around gap-[10px] p-2 duration-300 ease-in-out'
          : `w-380 fixed bottom-[20px] right-0 top-[115px] -mt-2 flex w-full max-w-96 transform grid-cols-7 flex-col justify-around gap-[10px] rounded-[20px] bg-gray-200 p-2 shadow-lg transition-transform duration-300 ease-in-out dark:dark:bg-navy-800 ${
              isVisible ? 'translate-x-0' : 'translate-x-full'
            }`
      }`}
    >
      {fullScreen ? (
        <div className="flex">
          <div className="z-0 h-[70vh] w-[70%]">
            <Chats
              receivedData={receivedData}
              AllTopicsPreviousData={AllTopicsPreviousData}
              topicsData={topicsData}
              getPreviouslyInsideData={getPreviouslyInsideData}
              responseData={responseData}
              setStoredValue={updateStoredValue}
              storedValue={storedValue}
              isLoading={isLoading}
              buttonClick={buttonClick}
              isNewChat={isButtonClick}
            />
          </div>
          <div className="w-[30%] rounded-[50px]">
            <PreviouslyData
              previousData={previousData}
              receivedData={receivedData}
              getNewPreviouslyInsideData={previouslyTopicData}
              getDeleteData={previouslyDeletedTopicData}
              AllTopicsPreviousData={AllTopicsPreviousData}
              buttonClick={buttonClick}
              isNewChat={isButtonClick}
              PreviouslyInsideData={PreviouslyInsideData}
              IsFullChatOpen={isVisible}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="mx-[-4px] mt-[-8px] flex items-center justify-between p-[8px] shadow-xl">
            <div className="z-50 w-48">
              <PreviouslyData
                previousData={previousData}
                receivedData={receivedData}
                getNewPreviouslyInsideData={previouslyTopicData}
                getDeleteData={previouslyDeletedTopicData}
                AllTopicsPreviousData={AllTopicsPreviousData}
                buttonClick={buttonClick}
                isNewChat={isButtonClick}
                PreviouslyInsideData={PreviouslyInsideData}
                IsFullChatOpen={isVisible}
              />
            </div>
            <div className="flex justify-end">
              <span className="mt-1.5 flex cursor-pointer p-2 text-2xl dark:text-white">
                <LuExternalLink
                  className="w-[20px]"
                  onClick={handlefullScreen}
                />
                <IoMdCloseCircleOutline
                  className="ml-2"
                  onClick={handleClick}
                />
              </span>
            </div>
          </div>
          <div className="z-0 h-full">
            <Chats
              receivedData={receivedData}
              AllTopicsPreviousData={AllTopicsPreviousData}
              topicsData={topicsData}
              getPreviouslyInsideData={getPreviouslyInsideData}
              responseData={responseData}
              setStoredValue={updateStoredValue}
              storedValue={storedValue}
              isLoading={isLoading}
              buttonClick={buttonClick}
            />
          </div>
        </>
      )}

      <div className={`${fullScreen ? 'flex' : ''}`}>
        <div className={`z-0 ${fullScreen ? 'w-[70%]' : 'mt-6'}`}>
          <ElasticSearch
            updateStoredValues={receiveDataFromChild}
            sentInput={sentInput}
            getPreviouslyInsideData={getPreviouslyInsideData}
            responseData={responseData}
            getNewPreviouslyInsideData={previouslyTopicData}
            isLoading={isLoading}
          />
        </div>

        <div className={`z-0 ${fullScreen ? 'ml-2 mt-[6px] w-[30%]' : 'mt-2'}`}>
          <NewChat isNewChat={isButtonClick} />
        </div>
      </div>
    </div>
  )
}

export default ChatContainer
