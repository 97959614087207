import React, { useRef, useState, useEffect } from 'react'
import profile from 'assets/img/dashboards/avatar.png'
import roboto from 'assets/img/dashboards/roboto.png'
import backgroundImg from 'assets/img/dashboards/chatbot-background.png'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Typewriter from 'common/Typewriter'
import ConfixaConstants from 'common/config/confixaConstants'
import { useLocation, useParams } from 'react-router-dom'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import styles from './custom-tailwind.module.css'

/**
 * Renders the chat interface with messages, user prompts, and bot responses.
 *
 * @param {object} receivedData - Data received from the user.
 * @param {array} AllTopicsPreviousData - Previous data related to topics.
 * @param {object} responseData - Data received in response to user input.
 * @param {function} updateStoredValues - Function to update stored values.
 * @param {function} setStoredValue - Function to set stored value.
 * @param {string} storedValue - Stored value.
 * @param {boolean} isLoading - Flag indicating loading state.
 * @param {function} buttonClick - Function triggered by button click.
 * @return {JSX.Element} The rendered chat interface.
 */
function Chats({
  receivedData,
  AllTopicsPreviousData,
  responseData,
  setStoredValue,
  storedValue,
  isLoading,
  buttonClick,
}) {
  const msgEnd = useRef(null)
  const [messages, setMessages] = useState([])
  const [fullScreen, setFullScreen] = useState(false)
  const userName = JSON.parse(localStorage.getItem('userData'))
  const location = useLocation()
  const { id } = useParams()
  const isChatBotUrl = location.pathname.includes('/chatbot')
  useEffect(() => {
    if (buttonClick) {
      setMessages([])
    }
    //eslint-disable-next-line
  }, [buttonClick])

  useEffect(() => {
    if (msgEnd.current && !fullScreen) {
      msgEnd.current.scrollToBottom()
    }
  }, [messages])

  useEffect(() => {
    if (!storedValue && !buttonClick) {
      setStoredValue(responseData?.topicId)
    }
    //eslint-disable-next-line
  }, [responseData])

  useEffect(() => {
    if (receivedData && receivedData.length > 0) {
      const newMessage = {
        message: receivedData,
        extra: 'bg-brand-400 font-dm w-fit  rounded-l-xl rounded-br-xl',
        text: 'text-white',
        image: profile,
        userType: 'userPrompt',
      }
      setMessages((prevMessages) => [...prevMessages, newMessage])
    }
  }, [receivedData])

  useEffect(() => {
    const value = location.pathname.includes('/chatbot')
    if (value) {
      setFullScreen(true)
    } else {
      setFullScreen(false)
    }
  }, [location])

  useEffect(() => {
    if (responseData && Object.keys(responseData)?.length > 0) {
      const botMessage = responseData?.answer
      const typingSpeed = 10
      let currentIndex = 0
      const typingInterval = setInterval(() => {
        if (currentIndex < botMessage.length) {
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages]
            if (
              updatedMessages.length === 0 ||
              updatedMessages[updatedMessages.length - 1].userType !==
                'botResponse'
            ) {
              updatedMessages.push({
                message: botMessage.substring(0, currentIndex + 1),
                extra:
                  'bg-white dark:bg-gray font-dm w-fit  rounded-l-xl rounded-br-xl',
                text: 'text-black',
                image: roboto,
                userType: 'botResponse',
              })
            } else {
              updatedMessages[updatedMessages.length - 1].message =
                botMessage.substring(0, currentIndex + 1)
            }
            return updatedMessages
          })
          currentIndex++
        } else {
          clearInterval(typingInterval)
        }
      }, typingSpeed)
      return () => clearInterval(typingInterval)
    }
  }, [responseData])

  useEffect(() => {
    if (
      (AllTopicsPreviousData && AllTopicsPreviousData.length > 0) ||
      (receivedData && Object.keys(receivedData)?.length > 0) ||
      (responseData && Object.keys(responseData)?.length > 0)
    ) {
      const userQuestions = AllTopicsPreviousData.map((question) => ({
        message: question,
        extra: 'bg-brand-400 font-dm w-fit  rounded-l-xl rounded-br-xl',
        text: 'text-white',
        timecolor: 'text-white',
        image: profile,
        userType: 'previouslyUserPrompt',
      }))

      const botAnswers = AllTopicsPreviousData.map((answer) => ({
        message: answer,
        extra:
          'bg-white dark:bg-custom-gray-bg font-dm w-fit  rounded-l-xl rounded-br-xl',
        text: 'text-black dark:text-white',
        image: roboto,
        userType: 'previouslyBotResponse',
      }))

      const interleavedMessages = []
      for (let i = 0; i < userQuestions.length; i++) {
        interleavedMessages.push(userQuestions[i])
        interleavedMessages.push(botAnswers[i])
      }
      setMessages(interleavedMessages)
    }
    //eslint-disable-next-line
  }, [AllTopicsPreviousData])

  /**
   * Renders the message content based on the message type.
   *
   * @param {any} message - The message to be rendered
   * @return {JSX.Element} The rendered message content
   */
  const renderMessageContent = (message) => {
    if (typeof message !== 'string') {
      return message
    }

    return (
      <div className={styles.resetTailwind}>
        <Markdown rehypePlugins={[rehypeRaw]}>{message}</Markdown>
      </div>
    )
  }

  return (
    <>
      {messages.length === 0 && (
        <div className="flex w-full flex-col items-center justify-center">
          <div className="text-center">
            <img src={backgroundImg} className="w-[200px]" alt="" />
          </div>
          {((id && isChatBotUrl) || (!buttonClick && !isChatBotUrl)) && (
            <div className="text-center font-bold dark:text-white">
              We're sorry, but we couldn't load your previous conversation.
              <br />
              Please try again, or if the problem persists, start a new
              conversation and let us know how we can assist you further.
            </div>
          )}
          {((!id && isChatBotUrl) || (buttonClick && !isChatBotUrl)) && (
            <div className="text-center font-bold dark:text-white">
              Let's start a new conversation! <br />
              How can I assist you today? Feel free to ask any questions or
              share what you need help with.
            </div>
          )}
        </div>
      )}
      {messages.length !== 0 && (
        <Scrollbars
          className={`relative h-full  pt-2 ${
            !fullScreen ? 'rounded-[20px]' : ''
          } `}
        >
          <div className="flex h-full w-full">
            <Scrollbars ref={msgEnd} autoHide>
              <div
                className={`relative overflow-hidden lg:max-h-[unset] ${
                  fullScreen ? 'w-[98%]' : ''
                } `}
              >
                {messages.map((msg, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index % 2 === 0
                          ? 'my-4 flex flex-row-reverse flex-col py-3.5'
                          : ''
                      }
                    >
                      <div
                        className={`flex items-start 
                                        ${
                                          msg.userType ===
                                            'previouslyUserPrompt' ||
                                          msg.userType === 'userPrompt'
                                            ? 'flex-row-reverse'
                                            : ''
                                        } `}
                      >
                        {msg.userType === 'previouslyUserPrompt' ||
                        msg.userType === 'userPrompt' ? (
                          <span className=" ml-4 mr-4 flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full bg-blueSecondary uppercase text-white dark:bg-brand-400">
                            {userName?.f_name && userName?.l_name
                              ? userName?.f_name.charAt(0) +
                                userName?.l_name.charAt(0)
                              : userName?.email_id.substring(0, 1) +
                                ' ' +
                                userName?.email_id.substring(1, 2)}
                          </span>
                        ) : (
                          <img
                            src={msg.image}
                            alt="profile type"
                            className={`mr-4 h-12 w-12 rounded-full ${
                              msg.userType === 'user' || 'Dummy'
                                ? 'ml-4 rounded-full '
                                : ''
                            }`}
                          />
                        )}

                        <div
                          className={`words-breaks whitespace-normal rounded-lg p-3 text-sm shadow-md  ${msg.extra}`}
                        >
                          {msg.userType === 'userPrompt' && (
                            <p className={`${msg.text} words-breaks`}>
                              {renderMessageContent(msg.message)}
                            </p>
                          )}
                          {msg.userType === 'previouslyUserPrompt' && (
                            <p
                              className={`${msg.text} words-breaks break-normal`}
                            >
                              {msg.message.question}
                            </p>
                          )}
                          {msg.userType === 'previouslyBotResponse' && (
                            <p
                              className={`${msg.text} words-breaks break-normal`}
                            >
                              {renderMessageContent(msg.message.answer)}
                            </p>
                          )}
                          {msg.userType === 'botResponse' && (
                            <p
                              className={`${msg.text} words-breaks break-normal`}
                            >
                              {renderMessageContent(msg.message)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
                {isLoading && (
                  <div className="relative ml-4 h-full overflow-hidden lg:max-h-[unset] lg:overflow-visible ">
                    <div>
                      <div className={`mb-4 flex items-center pb-3.5 `}>
                        <img
                          src={roboto}
                          alt="profile type"
                          className={`mr-4 h-12 w-12 rounded-full`}
                        />

                        <div
                          className={`words-breaks whitespace-normal rounded-lg bg-white p-3 shadow-md`}
                        >
                          {/* <div className="loader"></div> */}
                          <Typewriter
                            sentences={ConfixaConstants.LOADER_TEXTS}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </Scrollbars>
      )}
    </>
  )
}

export default Chats
