import React, { useState, useEffect } from 'react'
import { HiX } from 'react-icons/hi'
import Links from './components/Links'
import { RxHamburgerMenu } from 'react-icons/rx'
import confixaLogo from 'assets/img/others/confixa-logo.png'
import { useLocation } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import { getOneBillingData } from 'common/commonFunction'
import moment from 'moment'

import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar'
import { Scrollbars } from 'react-custom-scrollbars-2'
import RoutesComponent from 'routes'
import Card from 'components/card'
import ConfixaConstants from 'common/config/confixaConstants'
const { SUBSCRIPTION_EXPIRED, EMAIL, BUTTON_TEXT, CONFIXA } = ConfixaConstants

function SidebarHorizon(props) {
  const routes = RoutesComponent()
  const location = useLocation()
  const [isObservabilityPage, setIsObservabilityPage] = useState('')
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [billingData, setBillingData] = useState(null)
  const [loading, setLoading] = useState(true) // Add loading state
  const formattedDate = moment(billingData?.deactivatesOn).format(
    'Do MMMM YYYY'
  )
  const activatedDate = moment(billingData?.activatedOn).format('Do MMMM YYYY')
  const emailBody = encodeURIComponent(
    EMAIL.BODY.replace('{deactivateDate}', formattedDate)
      .replace('{activateDate}', activatedDate)
      .replace('{deactivateDate}', formattedDate)
  )

  useEffect(() => {
    setIsObservabilityPage(location.pathname === '/observability')
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname !== '/observability') {
      props.onOpenSideBar()
    }
  }, [location.pathname])

  useEffect(() => {
    const fetchBillingData = async () => {
      setLoading(true) // Set loading to true before fetching data
      try {
        const data = await getOneBillingData()
        setBillingData(data)
      } catch (error) {
        console.error('Error fetching billing data:', error)
      } finally {
        setLoading(false) // Set loading to false after data is fetched
      }
    }

    fetchBillingData()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1240)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getZIndex = () => {
    if (isObservabilityPage && sideBar) {
      return 'z-index-responsive'
    } else if (isObservabilityPage) {
      return 'z-50'
    } else {
      return ''
    }
  }

  const {
    open,
    onClose,
    variant,
    mini,
    hovered,
    setHovered,
    onExpand,
    isExpand,
    sideBar,
    onOpenSideBar,
  } = props
  const isSubscriptionPage = location.pathname.includes('/subscription')

  return (
    <>
      <div>
        {location.pathname === '/observability' && (
          <div>
            <span
              className={`sidebar-toggle-icon ${
                isExpand && !sideBar
                  ? 'expanded'
                  : sideBar
                  ? 'sidebar-open'
                  : ''
              }`}
              onClick={() => {
                onOpenSideBar()
              }}
            >
              {!sideBar && isExpand ? (
                <FaArrowLeft onClick={() => onOpenSideBar()} />
              ) : isExpand ? (
                <FaArrowRight onClick={() => onOpenSideBar()} />
              ) : (
                <FaArrowRight onClick={() => onOpenSideBar()} />
              )}
            </span>
          </div>
        )}
      </div>
      <div
        className={`sm:none ${isExpand ? 'w-[285px]' : 'w-[86px]'} ${
          mini === false
            ? 'w-[285px]'
            : mini === true && hovered === true
            ? 'w-[285px]'
            : 'w-[285px] xl:!w-[120px]'
        } 
        duration-175 linear fixed min-h-full transition-all ${
          variant === 'auth' ? 'xl:hidden' : 'xl:block '
        } ${
          sideBar && location.pathname === '/observability'
            ? '!z-50 -translate-x-[115%]'
            : ''
        } ${open ? '' : '-translate-x-[115%]'} ${
          isSmallScreen ? 'z-index-responsive' : ''
        }${getZIndex()}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Card
          extra={`ml-3 w-full h-[97vh] sm:mr-4 sm:my-3 m-7 !rounded-[20px]`}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderView={
              mini === false
                ? renderView
                : mini === true && hovered === true
                ? renderView
                : renderViewMini
            }
          >
            <div className="pb-[1%]">
              <div className="sticky top-0 z-[151] cursor-pointer bg-white dark:bg-darkBackGround">
                <span
                  className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                  onClick={onClose}
                >
                  <HiX />
                </span>
                <span
                  className="absolute right-4 top-4 block hidden cursor-pointer xl:block"
                  onClick={onExpand}
                >
                  <RxHamburgerMenu className="transition hover:scale-110 hover:text-brand-500 dark:hover:text-white" />
                </span>
                <div className={`ml-[14px] mt-[8px] flex items-center `}>
                  {isExpand ? (
                    <div
                      className={`ml-4 font-poppins text-[30px] font-medium capitalize tracking-normal text-blueSecondary dark:text-white ${
                        mini === false
                          ? 'block'
                          : mini === true && hovered === true
                          ? 'block'
                          : 'hidden'
                      }`}
                    >
                      <Link to="/">{CONFIXA.CONFIXA_HEADER}</Link>
                    </div>
                  ) : (
                    <Link to="/">
                      <img src={confixaLogo} className="mt-2 w-[34px]" alt="" />
                    </Link>
                  )}
                </div>
                <div className="mb-2 mt-2 h-px bg-gray-200 dark:bg-white/10" />
              </div>

              {/* Nav item */}
              <ul>
                <Links
                  mini={mini}
                  hovered={hovered}
                  routes={routes}
                  isExpand={isExpand}
                />
              </ul>
            </div>
            {!isSubscriptionPage &&
              isExpand &&
              (loading ? (
                <Card extra="w-full h-full bg-white mt-3 animate-pulse">
                  <div className="flex h-[200px] flex-col gap-y-8 p-4">
                    <div className="flex flex-col gap-y-4">
                      <div className="flex gap-x-4">
                        <div className="mt-4 flex w-full flex-col gap-y-2">
                          <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                          <div className="h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                        </div>
                        <div className="mt-4 flex w-full flex-col gap-y-2">
                          <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                          <div className="h-2 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                        </div>
                      </div>
                      <div className="mt-8 flex w-full flex-col gap-y-2">
                        <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                        <div className="h-2 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </div>
                  </div>
                </Card>
              ) : (
                billingData?.expiringBy <= 10 && (
                  <div className="mx-4 mb-[10%] rounded-lg bg-[#EE5D501A] p-3 dark:text-black">
                    <div className="pb-1 text-xl font-extrabold text-[#FF5652]">
                      {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_ALERT}
                    </div>
                    <div className="mt-1 text-lg font-semibold text-[#1B2559] dark:text-white">
                      {billingData?.expiringBy || '-'}{' '}
                      {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_DAYS_TOPIC}
                    </div>
                    <div className="mt-1 text-[95%] text-black dark:text-white">
                      {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_END_TOPIC}
                    </div>
                    <div className="text-black dark:text-white ">
                      {SUBSCRIPTION_EXPIRED.RENEW_TOPIC}
                    </div>
                    <button
                      onClick={() =>
                        (window.location.href = `mailto:${SUBSCRIPTION_EXPIRED.CONTACT_EMAIL}?subject=${EMAIL.SUBJECT}&body=${emailBody}`)
                      }
                      className="mt-[5%] w-full rounded-lg bg-brand-500 px-4 py-2 text-white dark:text-white"
                    >
                      {BUTTON_TEXT.RENEW}
                    </button>
                  </div>
                )
              ))}
          </Scrollbars>
        </Card>
      </div>
    </>
  )
}

export default SidebarHorizon
