export const columnsDataOrders = [
  {
    Header: 'S.NO',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'SERVICE',
  },
  {
    Header: 'PORT',
    accessor: 'port',
  },
  {
    Header: 'LAST DEPLOYED',
    accessor: 'updatedAt',
  },
  {
    Header: 'ACTION',
    accessor: 'ORDER ACTIONS',
    disableSortBy: true,
  },
]
