import React from 'react'
import { IoMdEye } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
// import { setRepoName } from 'common/commonFunction'
import TooltipHorizon from '../../components/tooltip'
import { useParams } from 'react-router-dom'
import { MdEdit } from 'react-icons/md'

/**
 * Renders a CardMenu component.
 *
 * @param {Object} props - the properties for the CardMenu component
 * @return {JSX.Element} - a Dropdown component representing the CardMenu
 */
function CardMenu({ cellValue, rowIndex, handlePortEdit }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const handleViewClick = (data) => {
    localStorage.setItem('serviceName', cellValue.name)
    localStorage.setItem('appType', cellValue?.appType)
    // if (cellValue?.[cellValue?.appType]?.gitRepo) {
    //   setRepoName(
    //     cellValue?.[cellValue?.appType]?.gitRepo.replace(
    //       /^https:\/\/github\.com\//,
    //       ''
    //     )
    //   )
    // }
    navigate(`/web-services/${id}/${cellValue?._id}`)
  }

  const onEditClick = () => {
    if (handlePortEdit) {
      handlePortEdit(rowIndex, cellValue) // Make sure 'port' is the correct field
    } else {
      console.error('handlePortEdit is not a function')
    }
  }

  return (
    <div className="">
      <div className="z-50 w-max rounded-xl bg-white py-1 text-sm  dark:!bg-navy-700 dark:shadow-none">
        <div className="flex">
          <div
            className="mr-2 flex cursor-pointer items-center rounded-full border p-1  font-bold text-[#000] hover:font-medium hover:text-black dark:text-white "
            onClick={onEditClick}
          >
            <span>
              <TooltipHorizon
                extra="border border-gray-200 dark:border-gray-700"
                trigger={
                  <p>
                    <span>
                      <MdEdit className="text-xl" />
                    </span>
                  </p>
                }
                content="Port Edit"
                placement="top"
              />
            </span>
          </div>
          {cellValue?.appType !== 'helm' && (
            <div
              className="flex cursor-pointer items-center rounded-full border p-1 font-bold  text-[#000] hover:font-medium hover:text-black dark:text-white"
              onClick={() => handleViewClick(cellValue)}
            >
              <span>
                <TooltipHorizon
                  extra="border border-gray-200 dark:border-gray-700"
                  trigger={
                    <p>
                      <span>
                        <IoMdEye className="text-xl" />
                      </span>
                    </p>
                  }
                  content="View"
                  placement="top"
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardMenu
