import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'

const CustomSelect = ({
  field,
  options,
  onChange,
  placeholderText = 'Select',
  isErrorField = false,
  value = [],
  isMultiValue,
}) => {
  const [darkmode, setDarkMode] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  useEffect(() => {
    const darkmodeSetting = localStorage.getItem('darkMode')
    setDarkMode(darkmodeSetting === 'true')
  }, [])

  const isAllSelected =
    options.length > 0 && value.length > 0 && value.length === options.length

  const handleStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      background: 'transparent',
      color: darkmode ? '#fff' : '#333',
      border: darkmode ? '1px solid #fff' : 'none',
      borderRadius: '10px',
      width: '100%',
      padding: '0',
      '&:hover, &:focus, &:active': {
        border: darkmode ? '1px solid #fff' : 'none',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      display: 'none', // Hide the selected value pills
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isErrorField ? '#f53939' : '#b1b5c4',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: darkmode ? '#070f2e' : '#fff',
      zIndex: 10,
    }),
  }

  const handleChange = (selectedOption) => {
    onChange(selectedOption || [])
    setMenuIsOpen(true)
  }

  const handleSelectAll = () => {
    if (isAllSelected) {
      onChange([])
    } else if (options.length > 0) {
      // Only select all if options are not empty
      onChange(options)
    }
    setMenuIsOpen(false) // Close the dropdown menu
  }

  const SelectAllOption = () => (
    <div className="select-all-option">
      <input
        type="checkbox"
        checked={isAllSelected}
        onChange={handleSelectAll}
        disabled={options.length === 0} // Disable the checkbox when options are empty
      />
      <label className="ml-2">Select All</label>
    </div>
  )

  return (
    <Select
      {...field}
      options={options}
      onChange={handleChange}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      placeholder={placeholderText}
      styles={handleStyles}
      value={value}
      isMulti={isMultiValue}
      components={{
        ClearIndicator: () => null,
        MenuList: (props) => (
          <>
            <SelectAllOption />
            <components.MenuList {...props} />
          </>
        ),
      }}
    />
  )
}

export default CustomSelect
