import { useState, useEffect } from 'react'
import { FiSearch } from 'react-icons/fi'
// import { HiPlus } from 'react-icons/hi'
import { MdEdit } from 'react-icons/md'
import TooltipHorizon from '../../../../components/tooltip'
import Card from 'components/card'
import CreateBranch from './CreateBranch'
import ConfixaConstants from 'common/config/confixaConstants'
import Switch from 'components/switch'

function SearchTableOrders({
  toggleState,
  branchLists,
  handleChangeEnviroment,
  fetchOrganization,
}) {
  const [showBranchPage, setShowBranchPage] = useState(false)
  const [globalFilter, setGlobalFilter] = useState('')
  const [handleCreatePage, setHandleCreatePage] = useState('add')
  const [rowData, setRowData] = useState()
  const [checked, setChecked] = useState(false)
  const { TABLE_HEADER, NO_DATA, TABS } = ConfixaConstants
  useEffect(() => {
    setShowBranchPage(false)
  }, [toggleState])

  const filteredData = branchLists.filter((item) =>
    item?.name?.toLowerCase().includes(globalFilter?.toLowerCase())
  )

  /**
   * Sets the `showBranchPage` state to `false`, hiding the branch list.
   *
   * @return {void} This function does not return anything.
   */
  const showBranchList = () => {
    setShowBranchPage(false)
  }

  const handleBranchPage = (value, data) => {
    setShowBranchPage(true)
    setHandleCreatePage(value)
    setRowData(data)
  }

  const handleChange = (event) => {
    setChecked(event.target.checked)
    let envType = event.target.checked ? 'All' : 'Default'
    handleChangeEnviroment(envType)
  }

  /**
   * Fetches the organization data by calling the fetchOrganization function.
   *
   * @return {void} This function does not return anything.
   */
  const getAllBranch = () => {
    fetchOrganization()
  }

  return (
    <>
      {toggleState === `${TABS.ENVIRONMENT_LIST}` && (
        <>
          {!showBranchPage && (
            <Card
              extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}
            >
              <>
                <div className="h-full w-full p-2 md:p-4">
                  <div className="flex h-full w-full flex-col items-center justify-between !pt-0 md:flex-row md:py-2">
                    {/* Search input */}
                    <div className="mb-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0 md:w-9/12">
                      <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
                        <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
                        <input
                          type="text"
                          placeholder="Search By Environment Name"
                          className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-full"
                          value={globalFilter}
                          onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <Switch
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                      />
                      <label className="text-md ml-2 text-navy-700 dark:text-white">
                        All
                      </label>
                    </div>
                    {/* Create Services button */}
                    {/* <button
                      onClick={() => handleBranchPage('Add')}
                      className="flex flex items-center justify-center rounded-2xl bg-brand-500 p-3 px-8 text-sm text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
                    >
                      <span className="mr-2">Add</span> <HiPlus />
                    </button> */}
                  </div>

                  {/* Table */}
                  <div className="mt-2 min-h-[150px] w-full overflow-x-scroll xl:overflow-hidden">
                    <table className="w-full">
                      <thead className="w-full">
                        <tr className="items-start border-b border-gray-200 dark:!border-white/10">
                          <th className="w-24 px-4 pb-[10px] text-start text-xs text-gray-600">
                            {TABLE_HEADER.SR_NO}
                          </th>
                          <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                            {TABLE_HEADER.ENVIRONMENT_NAME}
                          </th>
                          <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                            {TABLE_HEADER.KUBERNETES_CLUSTER}
                          </th>
                          <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                            {TABLE_HEADER.TOP_LEVEL_DOMAIN}
                          </th>
                          <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                            {TABLE_HEADER.ACTION}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((row, index) => (
                          <tr
                            className="border-b border-gray-200 dark:!border-white/10"
                            key={index}
                          >
                            <td className="w-24 px-4 text-start font-medium text-navy-700 dark:text-white">
                              {index + 1}
                            </td>
                            <td className="px-4 text-start font-medium text-navy-700 dark:text-white">
                              {row.name}
                            </td>
                            <td className="px-4 text-start font-medium text-navy-700 dark:text-white">
                              {row.k8Cluster.name}
                            </td>
                            <td className="px-4 text-start font-medium text-navy-700 dark:text-white">
                              {row.topLevelDomain.name}
                            </td>
                            <td className="px-4">
                              <div className="flex justify-start text-sm font-medium text-brand-500 underline hover:cursor-pointer dark:text-white">
                                <div className="w-max rounded-xl py-1 text-sm">
                                  <TooltipHorizon
                                    extra="border border-gray-200 dark:border-gray-700"
                                    trigger={
                                      <p
                                        className="flex cursor-pointer items-start rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                                        onClick={() =>
                                          handleBranchPage('Edit', row)
                                        }
                                      >
                                        <span>
                                          <MdEdit className="text-xl" />
                                        </span>
                                      </p>
                                    }
                                    content="Edit"
                                    placement="top"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* Display message if no data */}
                    {filteredData.length === 0 && (
                      <div className="text-md mt-3 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                        {NO_DATA}
                      </div>
                    )}
                  </div>
                </div>
              </>
            </Card>
          )}
          {showBranchPage === true && (
            <CreateBranch
              showBranchData={showBranchList}
              title={handleCreatePage}
              BranchData={rowData}
              getBranchList={getAllBranch}
            />
          )}
        </>
      )}
    </>
  )
}

export default SearchTableOrders
