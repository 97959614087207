import { PiWechatLogoLight } from 'react-icons/pi'
import ConfixaConstants from 'common/config/confixaConstants'
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'

const { BUTTON_TEXT } = ConfixaConstants
/**
 * Renders a new chat button component.
 *
 * @param {Object} props - The component props.
 * @param {function} props.isNewChat - A callback function to set the new chat state.
 * @return {JSX.Element} The rendered new chat button component.
 */
function NewChat({ isNewChat }) {
  let navigate = useNavigate()
  const location = useLocation()
  const [fullScreen, setFullScreen] = useState(false)
  /**
   * Sets the value of `isNewChat` to `true`.
   *
   * @return {void} No return value.
   */

  useEffect(() => {
    const value = location.pathname.includes('/chatbot')
    if (value) {
      setFullScreen(true)
    } else {
      setFullScreen(false)
    }
  }, [location])

  const handleClick = () => {
    isNewChat(true)
    if (fullScreen) {
      navigate('/chatbot')
    }
  }
  return (
    <>
      <div className="flex w-full items-center gap-3 rounded-2xl outline-none dark:!border-white/10 dark:!bg-navy-800">
        <button
          className="flex w-full items-center justify-center rounded-2xl bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 "
          type="button"
          onClick={() => handleClick()}
        >
          <span className="font-bold">{BUTTON_TEXT.NEW_CHAT} </span>
          <span className="ml-2 text-xl">
            <PiWechatLogoLight />
          </span>
        </button>
      </div>
    </>
  )
}

export default NewChat
