import Invite from './components/Invite'
import DeployGitRepository from './components/DeployGitRepository'
/**
 * Function that renders the Referrals component.
 *
 * @return {JSX.Element} The rendered Referrals component
 */
const Referrals = () => {
  return (
    <div className="h-full w-full gap-5 rounded-[20px]">
      <div className="col-span-12 mt-3 w-full md:mt-4 3xl:col-span-4">
        <DeployGitRepository />
      </div>
      <hr className="col-span-12 my-8" />
      <div className="col-span-12 w-full 3xl:col-span-4">
        <Invite />
      </div>
    </div>
  )
}

export default Referrals
