export const helmChartDataOrders = [
  {
    Header: 'SR',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'ENV',
    accessor: 'env',
  },
  {
    Header: 'ACTION',
    accessor: 'ORDER ACTIONS',
    disableSortBy: true,
  },
]
