import React, { useState, useEffect } from 'react'
import Dropdown from 'components/dropdown'
import { FiAlignJustify } from 'react-icons/fi'
// import { BsArrowBarUp } from 'react-icons/bs'
import Configurator from './Configurator'
import { IoMdLogOut } from 'react-icons/io'
import { IoWalletOutline } from 'react-icons/io5'
// import { IoMdNotificationsOutline } from 'react-icons/io'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useSpinner } from 'common/SpinnerLoader'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useToaster } from 'common/Toaster'
import { Link } from 'react-router-dom'
import ConfixaConstants from 'common/config/confixaConstants'
import RoutesComponent from 'routes'
import { getOneBillingData } from 'common/commonFunction'
import WebServices from 'assets/img/users/web-services.png'
import StaticSites from 'assets/img/users/static-sites.png'
import Cron from 'assets/img/users/cron.png'
import PostgreSQL from 'assets/img/users/postgre-sql.png'
import Redis from 'assets/img/users/redis.png'
import TooltipHorizon from 'components/tooltip'
import { GrTicket } from 'react-icons/gr'
const { TOAST, HEY, SUBSCRIPTION_EXPIRED } = ConfixaConstants
const apiService = ApiCaller()

/**
 * Component for the Navbar.
 *
 * @param {Object} props - The properties for the Navbar component.
 * @return {JSX.Element} The JSX for the Navbar component.
 */

const Navbar = (props) => {
  const support_ticket = process.env.REACT_APP_SUPPORT_TICKETS
  const support_submit_ticket = process.env.REACT_APP_SUPPORT_SUBMIT_TICKET
  const { addToast } = useToaster()
  const { applicationType } = useParams()
  const location = useLocation()
  const [breadcrum, setBreadcrum] = useState([])
  const [routesData, setRoutesData] = useState([])
  const { showSpinner, hideSpinner } = useSpinner()
  const [billingInfo, setBillingInfo] = useState(null)
  let navigate = useNavigate()
  const userName = JSON.parse(localStorage.getItem('userData'))
  const { onOpenSidenav, mini, brandText, isExpand, hovered, breadCrumbs } =
    props
  const [darkmode, setDarkmode] = useState(localStorage.darkMode === 'true')
  let appType = localStorage.getItem('appType')
  const routes = RoutesComponent()

  if (
    location.pathname.startsWith('/new-service/') ||
    location.pathname.startsWith('/deploying-service/')
  ) {
    appType = applicationType
  }

  useEffect(() => {
    if (routes) {
      setRoutesData(routes)
    }
  }, [routes])

  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        const data = await getOneBillingData()
        setBillingInfo(data)
      } catch (error) {
        console.error('Error fetching billing data:', error)
      }
    }

    fetchBillingData()
  }, [])

  useEffect(() => {
    /**
     * Asynchronously finds the route that matches the given location's pathname and sets the breadcrumb accordingly.
     *
     * @param {Object} location - The location object containing the pathname.
     * @return {Promise<void>} A promise that resolves when the breadcrumb is set.
     */
    const findRouteAndSetBreadcrumb = async (location) => {
      if (!Array.isArray(routesData) || !location) {
        console.error(
          'routesData or location is undefined or not in the expected format'
        )
        setBreadcrum([])
        return
      }

      const route = await routesData?.find((route) => {
        if (!route.path) {
          return false
        }

        if (location.pathname.startsWith('/settings')) {
          return route.path === '/settings'
        } else if (
          route.path === location.pathname ||
          (route.path.includes(':') &&
            compareSegments(route.path, location.pathname))
        ) {
          return true
        }
        return false
      })

      if (route) {
        // Find breadcrumb in route items if available, otherwise use the route's breadcrumb
        const breadcrumb =
          route.items?.find((item) => item.path === location.pathname)
            ?.breadcrum ||
          route.breadcrum ||
          []
        setBreadcrum(breadcrumb)
      } else {
        setBreadcrum([])
      }
    }

    /**
     * Compares segments of two paths.
     *
     * @param {string} routePath - The path of the route.
     * @param {string} locationPath - The path of the location.
     * @return {boolean} Returns true if segments match, false otherwise.
     */
    const compareSegments = (routePath, locationPath) => {
      const routeSegments = routePath.split('/')
      const locationSegments = locationPath.split('/')
      if (routeSegments.length !== locationSegments.length) return false
      for (let i = 0; i < routeSegments.length; i++) {
        if (
          !routeSegments[i].startsWith(':') &&
          routeSegments[i] !== locationSegments[i]
        ) {
          return false
        }
      }
      return true
    }

    findRouteAndSetBreadcrumb(location)
  }, [location, routesData])

  /**
   * handleLogout Function is used to handle user logout.
   * It sends a request to the server to logout the user,
   * clears local storage upon successful logout,
   * and navigates to the sign-in page.
   * Additionally, it displays a success toast message upon successful logout,
   * and an error toast message in case of failure.
   * @returns {Promise<void>} A Promise that resolves when the logout process is complete.
   */
  const handleLogout = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.LOGOUT_USER
      const payload = {}
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.status === 200) {
        localStorage.clear()
        navigate('/sign-in')
        addToast({
          title: TOAST.MESSAGES.SUCCESS.logoutSuccess,
          type: 'success',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (darkmode) document.body.classList.add('dark')
    else document.body.classList.remove('dark')
  }, [darkmode])

  /**
   * Returns a string representing the type of application based on the given column value.
   *
   * @param {string} appType - The appType is a string.
   * @return {Object} The type of object contains image and title.
   */
  const applicationTypeData = (appType) => {
    switch (appType) {
      case 'webApp':
        return {
          img: WebServices,
          title: 'Web Service',
        }
      case 'frontend':
        return {
          img: StaticSites,
          title: 'Static Sites',
        }
      case 'cron':
        return {
          img: Cron,
          title: 'Cron Jobs',
        }
      case 'postgresql':
        return {
          img: PostgreSQL,
          title: 'PostgreSQL',
        }
      case 'redis':
        return {
          img: Redis,
          title: 'Redis',
        }
      default:
        return {
          img: null,
          title: null,
        }
    }
  }

  return (
    <nav
      className={`duration-175 linear fixed right-3 top-0 flex flex-row flex-wrap items-center justify-between rounded-xl pt-2 transition-all ${
        !mini && isExpand
          ? 'w-[90%] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
          : mini && hovered
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
          : isExpand === false
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_152px)] 2xl:w-[calc(100vw_-_152px)]'
          : 'w-90%'
      } p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:right-[30px] md:top-0 xl:top-[0px] ${
        breadCrumbs === 'Observability' ||
        breadCrumbs === 'IAM' ||
        breadCrumbs === 'Security And Policies'
          ? 'hidden'
          : ''
      }`}
    >
      <div className="ml-[6px]">
        <div className="h-6 pt-1 dark:text-white">
          {breadcrum &&
            breadcrum.map((item, index) => (
              <React.Fragment key={index}>
                {index > 0 && ' / '}{' '}
                {/* Add a separator if it's not the first breadcrumb */}
                {item.breadCrumDisabled ? (
                  <span className="text-sm font-normal text-navy-700 dark:text-white dark:hover:text-white">
                    {item.label}
                  </span>
                ) : (
                  <Link
                    to={!item.breadCrumDisabled ? item.link : ''}
                    className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                  >
                    {item.label}
                  </Link>
                )}
              </React.Fragment>
            ))}
        </div>
        <p className="shrink text-[33px] text-navy-700 dark:text-white">
          <div className="flex items-center">
            {(location.pathname.startsWith('/web-services/') ||
              location.pathname.startsWith('/new-service/') ||
              location.pathname.startsWith('/deploying-service/')) && (
              <div className="m-1 mt-2 w-8 rounded-lg bg-white p-1 dark:bg-navy-700">
                <TooltipHorizon
                  extra="border border-gray-200 dark:border-gray-700"
                  trigger={
                    <img
                      src={applicationTypeData(appType).img}
                      className="rounded-full"
                      alt="img"
                    />
                  }
                  content={applicationTypeData(appType).title}
                  placement="top"
                />
              </div>
            )}
            <Link
              to="#"
              className="font-bold hover:text-navy-700 dark:hover:text-white"
            >
              {breadcrum &&
              breadcrum.find((item) => item.serviceName)?.serviceName
                ? breadcrum.find((item) => item.serviceName).serviceName
                : breadcrum &&
                  breadcrum.find((item) => item.projectName)?.projectName
                ? breadcrum.find((item) => item.projectName).projectName
                : brandText}
            </Link>
          </div>
        </p>
      </div>
      <div className="relative mt-[3px] flex h-[61px] w-[200px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-8 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[200px] md:flex-grow-0 md:gap-1 xl:w-[150px] xl:gap-2">
        {/* start Notification */}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5 hover:font-bold hover:text-navy-700" />
        </span>

        {/* <Dropdown
          button={
            <p className="cursor-pointer ">
              <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white hover:text-navy-700 hover:font-bold" />
            </p>
          }
          animation="origin-[65%_0%] origin-top-middle md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[285px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Confixa Coming Soon...
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for Our Confixa is available!
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={'py-2 top-4 -left-[90px] md:-left-[440px] w-max'}
        /> */}

        <Configurator
          mini={props.mini}
          setMini={props.setMini}
          theme={props.theme}
          setTheme={props.setTheme}
          darkmode={darkmode}
          setDarkmode={setDarkmode}
        />
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              setDarkmode(false)
              localStorage.darkMode = false
            } else {
              setDarkmode(true)
              localStorage.darkMode = true
            }
          }}
        ></div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <span className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blueSecondary uppercase text-white dark:bg-brand-400">
              {userName?.f_name && userName?.l_name
                ? userName?.f_name.charAt(0) + userName?.l_name.charAt(0)
                : userName?.email_id.substring(0, 1) +
                  ' ' +
                  userName?.email_id.substring(1, 2)}
            </span>
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold  text-navy-700 dark:text-white">
                    👋 <span className="ml-1">{HEY}</span>{' '}
                    <span className="capitalize">{userName?.f_name}</span>
                  </p>{' '}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />
              <div
                className="ml-4 mt-3 hover:cursor-pointer"
                onClick={() => window.open(support_submit_ticket, '_blank')}
              >
                <div className="flex items-center gap-2">
                  <p className="flex text-sm  font-medium text-navy-700 hover:font-bold  hover:text-navy-700 dark:text-white">
                    <GrTicket className="m-0.5" />
                    <span className="ml-1 mr-2">Submit a ticket</span>
                  </p>
                </div>
              </div>
              <div
                className="ml-4 mt-3 hover:cursor-pointer"
                onClick={() => window.open(support_ticket, '_blank')}
              >
                <div className="flex items-center gap-2">
                  <p className="flex text-sm  font-medium text-navy-700 hover:font-bold  hover:text-navy-700 dark:text-white">
                    <GrTicket className="m-0.5" />
                    <span className="ml-1 mr-2">Tickets</span>
                  </p>
                </div>
              </div>
              <div
                className="ml-4 mt-3 hover:cursor-pointer"
                onClick={() => navigate('/subscription')}
              >
                <div className="flex items-center gap-2">
                  <p className="flex text-sm  font-medium text-navy-700 hover:font-bold  hover:text-navy-700 dark:text-white">
                    <IoWalletOutline className="m-0.5" />
                    <span className="ml-1 mr-2">Subscription </span>
                    {billingInfo?.expiringBy &&
                      billingInfo.expiringBy <= 10 && (
                        <span className="rounded-lg bg-[#EE5D501A] px-2 text-[70%] text-[#EE5D50]">
                          <span className="text-red-500">
                            {SUBSCRIPTION_EXPIRED.SUBSCRIPTION_EXPIRING}{' '}
                            {billingInfo.expiringBy} {SUBSCRIPTION_EXPIRED.DAYS}
                          </span>
                        </span>
                      )}{' '}
                  </p>{' '}
                </div>
              </div>
              <div
                className="ml-4 mt-3 hover:cursor-pointer "
                onClick={() => handleLogout()}
              >
                <div className="flex items-center gap-2">
                  <p className="flex text-sm   font-medium text-red-500 hover:font-bold hover:text-red-500 ">
                    <IoMdLogOut className="m-0.5" />
                    <span className="ml-1">Log Out </span>
                  </p>{' '}
                </div>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  )
}

export default Navbar
