import { useState, useEffect } from 'react'
import ChatIntegration from '../views/chatBot'
import { AiFillWechat } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'

/**
 * ChatAi component that manages the visibility and fullscreen mode.
 *
 * @return {JSX.Element} The JSX element representing the ChatAi component
 */
const ChatAi = () => {
  const [isVisible, SetIsVisible] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const location = useLocation()
  const showChatBoat = process.env.REACT_APP_SHOW_CHAT_BOAT

  useEffect(() => {
    if (showChatBoat === 'true') {
      const value = location.pathname.includes('/chatbot')
      if (value) {
        setFullScreen(true)
      } else {
        setFullScreen(false)
      }
    }
  }, [location])

  return (
    <>
      {showChatBoat === 'true' && !fullScreen && (
        <div>
          <div
            className="z-1 fixed bottom-8 right-8 h-[40px] w-[40px] cursor-pointer rounded-full bg-blue-700"
            onClick={() => SetIsVisible(!isVisible)}
          >
            <span className="flex h-full cursor-pointer items-center justify-center text-[25px] text-white">
              <AiFillWechat />
            </span>
          </div>
          <ChatIntegration
            isVisible={isVisible}
            onValueChange={() => SetIsVisible(!isVisible)}
          />
        </div>
      )}
    </>
  )
}

export default ChatAi
