import React from 'react'
import { IoMdEye } from 'react-icons/io'
import { MdEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import TooltipHorizon from '../tooltip'
/**
 * Renders a CardMenu component.
 *
 * @param {Object} props - the properties for the CardMenu component
 * @return {JSX.Element} - a Dropdown component representing the CardMenu
 */
function ProjectCardMenu(props) {
  const navigate = useNavigate()
  const handleViewClick = async (data) => {
    navigate(`/applications/${data.cellValue._id}`)
    localStorage.setItem('projectName', data.cellValue.name)
  }

  /**
   * A function to handle the click event for editing a project.
   *
   * @param {Object} data - the data object containing the project details
   * @return {void}
   */
  const handleEditClick = (data) => {
    navigate(`/edit-project/${data.cellValue._id}`)
  }

  return (
    <div className="justify-left flex">
      <div className="w-max px-4 py-1 pl-0 text-sm">
        <TooltipHorizon
          extra="border border-gray-200 dark:border-gray-700"
          trigger={
            <p
              className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
              onClick={() => handleViewClick(props)}
            >
              <span>
                <IoMdEye className="text-xl" />
              </span>
            </p>
          }
          content="View"
          placement="top"
        />
      </div>
      <div className="w-max rounded-xl py-1 text-sm">
        <TooltipHorizon
          extra="border border-gray-200 dark:border-gray-700"
          trigger={
            <p
              className="flex cursor-pointer items-center rounded-full border p-1 font-bold  text-[#000] hover:font-medium hover:text-black dark:text-white"
              onClick={() => handleEditClick(props)}
            >
              <span>
                <MdEdit className="text-xl" />
              </span>
            </p>
          }
          content="Edit"
          placement="top"
        />
      </div>
    </div>
  )
}

export default ProjectCardMenu
