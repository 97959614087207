import React from 'react'
import { HiPlus } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

/**
 * Renders a button component with a specified button text and an optional flag for creating a service.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.buttonText - The text to display on the button.
 * @param {boolean} [props.isCreateService=false] - A flag indicating if the button is for creating a service.
 * @return {JSX.Element} The rendered button component.
 */
function GetStartedButton({ buttonText, isCreateService = false }) {
  let navigate = useNavigate()

  return (
    <>
      <div>
        <button
          onClick={() => {
            navigate(`/create-project`)
          }}
          className="flex items-center rounded-[20px] bg-brand-500 px-5 py-2 text-sm font-medium text-white transition duration-200 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 sm:px-3 sm:text-xs md:text-base lg:px-5 lg:text-base"
        >
          {buttonText}{' '}
          <span className="ml-2 text-xl">
            <HiPlus />
          </span>
        </button>
      </div>
    </>
  )
}

export default GetStartedButton
